import { Listbox } from "@headlessui/react";
import * as S from "core/components/Styled";
import { uuid } from "core/utils/index";
import styled from "styled-components";
import { ArrowIcon } from "./icons";

export type ListItem = {
  name: string;
  value: string | number;
};

export type SmallListItem = {
  name: string;
  code: string;
  h_code: string;
  ref_key: string;
  type: string;
  selected?: boolean;
};

interface SelectProps {
  list: {
    name: string;
    code: string;
    data: SmallListItem[];
  };
  selected: SmallListItem | string | number | object;
  setSelect: (value: SmallListItem, name: string, code: string) => void;
  className?: string;
  disabled?: boolean;
  status?: "placeholder" | "active";
  style?: any;
  optionHeight?: number;
  placeHolder?: string;
  minWidth?: string;
}

export default function SmallSelect({
  className,
  list,
  selected,
  setSelect,
  disabled = false,
  style,
  optionHeight,
  placeHolder
}: SelectProps) {
  const valueFormat = () => {
    let ret: string;
    if (!selected) {
      ret = "";
    } else if (typeof selected === "string") {
      const _data: SmallListItem = list?.data?.find(
        (item) => item.code === selected
      );

      if (_data) ret = _data.name;
      else {
        const _d: SmallListItem = list?.data?.find(
          (item) => item.name === selected
        );
        if (_d) ret = _d.name;
      }
    } else if (typeof selected === "object") {
      const _data: SmallListItem = list?.data?.find((item) => item == selected);

      if (_data) ret = _data.name;
      else {
        const _d: SmallListItem = list?.data?.find((item) => item === selected);
        if (_d) ret = _d.name;
      }
    } else {
      let _ret: SmallListItem;
      Object.entries(selected)?.map((item) => {
        const key = item[0];
        const value = item[1].code;

        if (key === "value") {
          _ret = list?.data?.find((item) => item.code === value);
        }
      });
      if (_ret) ret = _ret.name;
    }

    return ret ? ret : placeHolder ? placeHolder : "선택";
  };

  const setLocalSelect = (item: SmallListItem) => {
    setSelect(item, list.name, list.code);
  };
  return (
    <div className={["selectComp", className].join(" ")}>
      <Listbox
        value={selected}
        onChange={(sList: any) => setLocalSelect(sList)}
        disabled={disabled}
      >
        <Wrapper style={style}>
          <ButtonWrapper>
            {({ open }) => {
              return (
                <Button disabled={disabled} open={open}>
                  <SelectedValue placeHolder={placeHolder}>
                    {valueFormat()}
                  </SelectedValue>
                  <ArrowIcon
                    width={16}
                    height={16}
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: open ? "rotate(270deg)" : "rotate(90deg)"
                    }}
                  />
                </Button>
              );
            }}
          </ButtonWrapper>

          <Options height={optionHeight} style={{ zIndex: 5 }}>
            {list?.data?.map((listItem) => (
              <ListboxOption key={uuid()} value={listItem}>
                {({ selected, active }) => (
                  <OptionItem selected={selected} active={active}>
                    {listItem?.name}
                  </OptionItem>
                )}
              </ListboxOption>
            ))}
          </Options>
        </Wrapper>
      </Listbox>
    </div>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const ButtonWrapper = styled(Listbox.Button)`
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
`;

const Button = styled.div<{ disabled: boolean; open: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 30px;
  font-size: 13px;
  border: ${({ open, theme }) =>
    open
      ? `1px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.gray150}`};
  border-bottom: ${(props) =>
    props.open ? "none" : `1px solid ${props.theme.colors.gray150}`};
  border-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.open ? "0" : "4px")};
  border-bottom-right-radius: ${(props) => (props.open ? "0" : "4px")};
  background-color: ${({ disabled, theme }) =>
    disabled ? "#EAEAEA" : theme.colors.white};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray150 : theme.colors.black222};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const SelectedValue = styled(S.StyledText)<{
  children: string;
  placeHolder?: string;
}>`
  white-space: nowrap;
  font-size: 13px;
  color: ${(props) =>
    props.children === "선택" || props.children === props.placeHolder
      ? "#999"
      : "#222"};
`;

const Options = styled(Listbox.Options)<{ height?: number }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
  max-height: ${(props) => (props.height ? props.height + "px" : "400px")};
  overflow-y: scroll;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  padding: 4px 8px 12px 8px;
`;

const ListboxOption = styled(Listbox.Option)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const OptionItem = styled(S.StyledText)<{ selected: boolean; active: boolean }>`
  cursor: pointer;
  padding: 8px;
  width: 100%;
  font-size: 13px;
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.black222};
  transition: color 0.2s ease-in-out;
  white-space: nowrap;

  background: ${(props) =>
    props.selected ? "rgba(108, 98, 209, 0.2)" : props.theme.colors.white};

  &:hover {
    background-color: #f0eeff;
  }
`;
