import encode from "jwt-encode";
import { decodeToken } from "react-jwt";

/**
 * 유저 정보 jwt token 암호화처리 ( 내부망이라 간단히 암호화처리 )
 */
const SECRET_KEY = "sungwon";

const JwtUtil = {
  sign: (data: any): string => {
    return encode(data, SECRET_KEY);
  },
  verified: (token: string): any => {
    return decodeToken(token);
  },
};
export default JwtUtil;
