import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";


interface TextInputControlProps {
  type : string;
  id  : string;
  title : string;
  formik? : any;
  onChange?: (e: any) => void;
  onKeyPress? : (e: any) => void;
  styles : any;
  autoComplete? : string;
  isValidate? : boolean;
  placeholder? : string;
  isDisabled? : boolean;
  multiline? : boolean;
  value? :string
}

const TextInputControl = ({
  type,
  id,
  title,
  formik,
  onChange,
  onKeyPress,
  styles,
  autoComplete = "off",
  isValidate = false,
  isDisabled = false,
  multiline = false,
  placeholder,
  ...rest
}:TextInputControlProps) => {
  const error = formik?.touched[id] && formik?.errors[id] ? true : false;
  const helperText = formik?.errors[id] ? formik?.errors[id] : "";
  // 설정 카테고리 관리
  // - id필드가 name인 경우 duplicated 에러메시지 표시
  const errorMarginBottom =
    error || (id == "name" && formik.status?.duplicated) ? { mb: "4px" } : {};

  const inputStyles = {
    outlineInputStyle: {
      display: "flex",
      alignItems: "start",
      position: "relative",
    },
    validationHeader: { display: "flex", alignItems: "center" },
    validationIcon: {
      width: 20,
      height: 20,
      mb: 1,
      mr: 1,
    },
  };

  return (
    <Box
      sx={{ ...styles.row, ...errorMarginBottom, ...outlinedInputStyle}}
    >
      {isValidate ? (
        <Box sx={inputStyles.validationHeader}>
          {/* <Avatar
            sx={inputStyles.validationIcon}
            src={Icons.CheckIcon}
            variant="square"
          /> */}
          <Typography sx={styles.label} variant="body2">
            {title}
          </Typography>
        </Box>
      ) : (
        <Typography sx={styles.label} variant="body2">
          {title}
        </Typography>
      )}
      <Box sx={type == "auth" ? inputStyles.outlineInputStyle : {}}>
        <OutlinedInput
          sx={styles.outlinedInput}
          id={id}
          type={type == "auth" ? "password" : type }
          size="medium"
          multiline={multiline}
          //value={formik?.values[id]}
          fullWidth={true}
          value={rest.value}
          onChange={onChange ? onChange : formik?.handleChange}
          onKeyPress={onKeyPress ? onKeyPress : null}
          onBlur={formik?.handleBlur}
          onReset={() => formik?.setFieldValue(id, "")}
          error={error}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={isDisabled}
          {...rest}
        />
        {id == "name" && formik.status?.duplicated && (
          <FormHelperText sx={styles.formHelperText} error>
            {formik.status?.duplicated}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default TextInputControl;

const outlinedInputStyle = {
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#414141",
    width:'100%'
  },
};
