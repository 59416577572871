import * as S from "core/components/Styled";
import DatePicker from "core/components/common/SmallDatePicker";
import Select from "core/components/common/SmallSelect";
import Pagination from "core/components/forms/Pagination";
import styled from "styled-components";
import constants from "core/utils/constants";
import {
  orderListStyled,
  orderMainStyled,
  orderOptionListStyled
} from "core/types";

export const InputStyles = {
  outlinedInput: {
    width: "auto",
    height: 30,
    backgroundColor: "white",
    borderRadius: "15px"
  },
  formControlLabel: { ml: "-9px" },
  checkBox: { color: "#979797", fontSize: "14px" },
  icon: {
    position: "absolute",
    top: 10,
    right: 5,
    width: 20,
    height: 20
  },
  selectControl: {
    backgroundColor: "#0a0a0a",
    color: "#bdbdbd",
    fontSize: 15,
    height: 30,
    "&:hover": {
      borderColor: "#414141"
    },
    "& .MuiSelect-select": {
      fontSize: 15,
      color: "#bdbdbd"
    }
  },
  smallInputStyle: {
    width: "95%",
    fontSize: 13
  },
  smallInputNonBorderStyle: {
    width: "95%",
    fontSize: 13,
    border: 0
  },
  smallNonBorderInputStyle: {
    width: "95%",
    fontSize: 13,
    border: 0
  }
};

const DefaultStyle = {
  titleSize: 15,
  subTitleSize: 13
};

export const LayerStyles = {
  dialogWrapper: {
    overflow: "auto",
    "& .MuiDialog-container": {
      alignItems: "center",
      borderRadius: "5px"
    },
    "& .MuiDialog-paper": {
      width: "800px",
      border: "1px solid #464646",
      boxShadow: "none"
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#fff",
      pr: 1,
      borderBottom: "1px solid #ccc"
    },
    "& .MuiDialogContent-root": {
      overflow: "auto",
      padding: 0,
      minHeight: "100px",
      maxHeight: "700px",
      "& .MuiBox-root": {}
    }
  },
  dialogLargeWrapper: {
    overflow: "auto",
    "& .MuiDialog-container": {
      alignItems: "center",
      borderRadius: "5px"
    },
    "& .MuiDialog-paper": {
      width: "1024px",
      border: "1px solid #464646",
      boxShadow: "none"
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#fff",
      pr: 1,
      borderBottom: "1px solid #ccc"
    },
    "& .MuiDialogContent-root": {
      overflow: "auto",
      padding: 0,
      minHeight: "400px",
      maxHeight: "600px",
      "& .MuiBox-root": {}
    }
  },
  dialogExtraLargeWrapper: {
    overflow: "auto",
    "& .MuiDialog-container": {
      alignItems: "center",
      borderRadius: "5px"
    },
    "& .MuiDialog-paper": {
      width: "auto",
      border: "1px solid #464646",
      boxShadow: "none"
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#fff",
      pr: 1,
      borderBottom: "1px solid #ccc"
    },
    "& .MuiDialogContent-root": {
      overflow: "auto",
      padding: 0,
      minHeight: "400px",
      maxHeight: "900px",
      "& .MuiBox-root": {}
    }
  },
  dialogSmallWrapper: {
    overflow: "auto",
    "& .MuiDialog-container": {
      alignItems: "center",
      borderRadius: "5px"
    },
    "& .MuiDialog-paper": {
      width: "600px",
      border: "1px solid #464646",
      boxShadow: "none"
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#fff",
      pr: 1,
      borderBottom: "1px solid #ccc"
    },
    "& .MuiDialogContent-root": {
      overflow: "auto",
      padding: 0,

      minHeight: "200px",
      maxHeight: "500px",
      "& .MuiBox-root": {}
    }
  }
};

export const CommonListStyles = {
  navTitleWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "55px",
    zIndex: 9,
    "&.headerfixed": {
      position: "fixed",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      left: "86px",
      top: "56px",
      width: "96%",
      maxWidth: "100%",
      padding: "16px 100px 10px 16px",
      height: "55px",
      zIndex: 9,
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc"
    },
    "&.headerfixed&.halfwidth": {
      width: "calc( 100% - 636px) !important",
      padding: "16px 20px 10px 16px"
    },
    "&.fullwidth": {
      width: "100%"
    },
    "&.slide-in": {
      animation: "slideIn 0.5s ease-in"
    }
  }
};

export const popUpDetailStyles = {
  wrapper: {
    backgroundColor: "rgba(0, 0, 0, 0.25)"
  },
  toggleHeaderCollapse: {
    padding: 2,
    border: "1px solid #ccc",
    borderTop: 0,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px"
  },
  infoTopTitleWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "auto",
    minHeight: "30px",
    bgcolor: "#fff",
    marginBottom: 1,
    marginTop: 2
  },
  infoTopTitleWrapColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    height: "auto",
    minHeight: "30px",
    bgcolor: "#fff",
    marginBottom: 1
  },
  infoTopSubTitleColumnWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: "100%",
    height: "auto",
    minHeight: "34px",
    bgcolor: "#fff"
  },
  orderDigestLeftWrap: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginTop: 2,
    padding: "5px",
    "& span.strong": {
      fontWeight: "bold"
    }
  },
  orderDigestRightWrap: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    marginTop: 2,
    padding: "5px"
  },
  menuDefaultButton: {
    minwidth: 85,
    width: "auto",
    height: 30,
    backgroundColor: "#fff",
    border: "1px solid #efefef",
    display: "flex",
    justfyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: "5px",
    ":hover": {
      bgcolor: "#333",
      color: "#fff",
      "& > h6": {
        color: "#fff"
      }
    }
  },
  menuSelectButton: {
    minwidth: 85,
    width: "auto",
    height: 30,
    backgroundColor: constants.defaultColor,
    color: "#fff",
    border: "1px solid #efefef",
    marginRight: "5px",
    textAlign: "center",
    ":hover": {
      bgcolor: "#333",
      color: "#fff",
      "& > h6": {
        color: "#fff"
      }
    }
  },
  button: {
    width: 85,
    minWidth: 85,
    height: 40,
    backgroundColor: "#fff",
    border: "1px solid #555"
  },
  emptyWrap: {
    display: "flex",
    width: "100%",
    height: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff"
  },
  dataTable: {
    borderRadius: 0
  },
  headStyle: {
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    fontSize: 13,
    borderTop: "1px solid #ccc",
    height: 40
  },
  infoBoxFirstTopLayer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    borderCollapse: "collapse"
  },
  infoBoxTopLayer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    borderBottom: "1px solid #ccc",
    borderCollapse: "collapse"
  },
  infoBoxHeaderLeft: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "auto",
    minHeight: "30px"
  },
  infoBoxHeaderRight: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "auto",
    minHeight: "30px"
  },
  infoBoxTitleArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    height: "auto",
    minHeight: "40px",
    bgcolor: "#efefef",
    width: "auto",
    minWidth: "100px"
  },
  infoBoxDataArea: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "10px",
    letterSpacing: -1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap"
  },
  infoBoxDataAreaLeft: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    letterSpacing: -1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap"
  },
  infoBoxDataAreaCenter: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: -1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap"
  },
  infoBoxDataAreaLeftFlexColumn: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: "10px"
  },
  infoBoxDataAreaScroll: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 2,
    height: "auto",
    borderBottom: "0px",
    minHeight: "70px",
    maxHeight: "250px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 2,
      height: 2
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      backgroundColor: "#efefef"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: constants.defaultColor || "#e70201"
    },
    "&.MuiPaper-root": {
      borderRadius: "0px",
      boxShadow: "0px 0px 0px  px 0px rgba(0,0,0,0.75);"
    }
  },
  infoBoxDefault: {
    width: "32%",
    maxWidth: "32%",
    marginRight: "5px",
    marginBottom: "5px",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      bgcolor: "#333",
      color: "#fff",
      "& > h6": {
        color: "#fff"
      }
    }
  },

  infoBoxSmall: {
    width: "auto",
    maxWidth: "160px",
    marginRight: "5px",
    marginBottom: "5px",
    border: "1px solid #ccc",
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      bgcolor: "#333",
      color: "#fff",
      "& > h6": {
        color: "#fff"
      }
    }
  },
  titleText: {
    fontSize: "13px",
    fontWeight: "600"
  },
  dataText: {
    width: "auto",
    fontSize: "13px",
    fontWeight: "normal",
    letterSpacing: -1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ":hover": {
      overflow: "visible",
      whiteSpace: "normal"
    }
  },
  verySmallButton: {
    height: "20px",
    minWidth: "30px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "3px"
  }
};

export const dataTableStyles = {
  wrapper: {
    width: "100%",
    margin: 0,
    padding: 0
  },
  tableContainer: {
    height: "calc(100% - 220px)", //hasTablePagination true일때, false > 160px
    // 0.25 + 0.45 = 0.7
    //backgroundColor: "rgba(14, 14, 14, 0.45)",

    "&::-webkit-scrollbar": {
      width: 2,
      height: 2
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      backgroundColor: "#efefef"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: constants.defaultColor || "#e70201"
    },
    "&.MuiPaper-root": {
      borderRadius: "0px",
      boxShadow: "0px 0px 0px  px 0px rgba(0,0,0,0.75);"
    }
  }
};

// order/list.tsx에서 사용
export const SearchSectionWrapper: any = styled.section`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;
  border-bottom: 1px solid #999;
`;

export const MultiRowBoxList: any = styled.div<{ isDevideScreen?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 8px;
  background: #f9f9f9;
  width: 100%;
`;

export const MultiBoxList: any = styled.div<{ isDevideScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
  background: #f9f9f9;
  width: ${(isDevideScreen) =>
    isDevideScreen ? "100%" : "calc( 100% - 800px)"};
`;

export const RightDetailWrap: any = styled.div<{ rightDetailHeight: any }>`
  display: flex;
  position: fixed;
  top: 55px;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  background: #ccc;
  border-radius: 0;
  width: ${constants.rightFixedWidth};
  height: ${(props: any) =>
    props.rightDetailHeight ? `${props.rightDetailHeight}px)` : "100vh"};
  border-left: 1px solid #ccc;
`;

export const RightDetailShadowWrap: any = styled.div<{
  rightDetailHeight: any;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  background: #ccc;
  border-radius: 0;
  width: 800px;
  height: ${(props: any) =>
    props.rightDetailHeight ? `${props.rightDetailHeight}px)` : "100vh"};
  border-left: 1px solid #ccc;
`;

export const SearchAreaWrappper: any = styled.div<{
  floatAlign?: string;
  isBorder?: boolean;
  isShow?: boolean;
}>`
  display: ${(props: any) => (props.isShow ? "flex" : "none")};
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: any) =>
    props.floatAlign == "right" ? "flex-end" : "flex-start"};
  padding: 15px 8px;
  gap: 8px;
  width: 100%;
  border: ${(props: any) => (props.isBorder ? "1px solid #cccccc" : "0px")};
  border-radius: 4px;
`;

export const PartWrapper: any = styled.div<{
  minWidth?: string;
  isHideLable?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  min-width: ${(props: any) =>
    props.isHideLable ? "100px" : props.minWidth ? props.minWidth : "200px"};
`;

export const DatePickerWrapper: any = styled(DatePicker)`
  min-width: 120px;
  max-width: 150px;
  z-index: 1 !important;
`;

export const SelectWrapper: any = styled(Select)`
  min-width: ${(props: any) => (props.minWidth ? props.minWidth : "120px")};
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : "150px")};
`;
export const SelectFullWidthWrapper: any = styled(Select)`
  width: 95%;
  min-width: 200px;
`;

export const Label: any = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #222222;
  min-width: fit-content;
  margin-right: 5px;
  & small {
    font-size: 14px;
  }
`;

//use popupDetail.tsx

export const ScrollTopButton: any = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  zindex: 9999;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const Header: any = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding: 0px 14px;
  min-height: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  z-index: 99;
  overflow: hidden;
`;

export const LeftHeader: any = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0px 14px;
  min-height: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  overflow: hidden;
`;

export const LeftSiteHeader: any = styled.div<{ isFlexDirection?: string }>`
  background-color: #fff;
  width: 100%;
  padding: 0px 14px;
  height: 90px;
  display: flex;
  flex-direction: ${({ isFlexDirection }) =>
    isFlexDirection ? isFlexDirection : "row"};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  z-index: 1;
  overflow: hidden;
`;
export const HeaderFixed: any = styled.div`
  position: absoulte;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  padding: 0px 14px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  z-index: 999999999;
`;

export const Footer: any = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0px 14px;
  min-height: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  z-index: 99;
  overflow: hidden;
`;
export const LoadingWrap: any = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: transparent;
  height: 100%;
  width: 100%;
`;

export const ControlArea: any = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  height: 100%;
`;

export const ControlColumnArea: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  height: 100%;
`;

export const ContentArea: any = styled.div<{ scroll?: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: ${({ scroll }) => (scroll ? "scroll" : "hidden")};
  & > div {
    border-right: 1px solid #ccc;
  }
`;
export const Content: any = styled.div<{
  flex?: number;
  padding?: number;
  scroll?: boolean;
}>`
  flex: ${(props) => props.flex};
  width: 100%;
  height: auto;
  padding: ${({ padding }) => padding || "10px"};
  overflow: ${({ scroll }) => (scroll ? "scroll" : "hidden")};
  padding-bottom: ${({ scroll }) => (scroll ? "20px" : "0")};
`;
export const PopUpBottom: any = styled(S.StyledBottom)<{ space?: string }>`
  justify-content: ${(props) =>
    props.space
      ? props.space === "center"
        ? props.space
        : "flex-" + props.space
      : ""};
`;

export const DetailBoxrappper: any = styled.div<{
  floatAlign?: string;
  isBorder?: boolean;
  isShow?: boolean;
  isOverflow?: boolean;
}>`
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: any) => {
    return props.floatAlign === "right" ? "flex-end" : "flex-start";
  }};
  width: 100%;
  min-height: 40px;
  border: ${(props: any) => (props.isBorder ? "1px solid #cccccc" : "0px")};
  border-collapse: collapse;
  border-radius: 5px;
  overflow: ${(props: any) => (props.isOverflow ? "hidden" : null)};
`;

export const DetailColumnBoxrappper: any = styled.div<{
  floatAlign?: string;
  isBorder?: boolean;
  isShow?: boolean;
  isOverflow?: boolean;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props: any) =>
    props.floatAlign == "right" ? "flex-end" : "flex-start"};
  width: 100%;
  min-height: 40px;
  border: ${(props: any) => (props.isBorder ? "1px solid #cccccc" : "0px")};
  border-collapse: collapse;
  border-radius: 5px;
`;

export const PopHiddenLayer: any = styled.div`
  display: none;
  position: absolute;
  top: 25px;
  left: 20xp;
  height: 170px;
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  z-index: 66;
  &:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 66;
    & > h2:hover {
      color: ${constants.defaultColor};
      font-weight: bold;
    }
  }
`;

export const PopHiddleSubTitle: any = styled.h2`
  font-size: 14px;
  color: #555555;
  line-height: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const PopHiddenIconWrap: any = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  &:hover {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & > span {
      transform: rotate(180deg);
    }
  }
`;

export const PaginationWrapper: any = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 64px;
`;

export default DefaultStyle;
