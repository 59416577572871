import { useCalendar } from "@h6s/calendar";
import { Menu } from "@headlessui/react";
import * as S from "core/components/Styled";
import classnames from "classnames/bind";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import styled from "styled-components";
import styles from "./DatePicker.module.scss";
import { ArrowIcon, CalendarIcon, CloseIcon } from "./icons";
const cx = classnames.bind(styles);

interface DateSelectProps {
  handleDate: (date: Date) => void;
  selectedDate: number;
  className?: string;
  showMonthOnly?: boolean;
  style?: any;
  placeholder?: string;
}

export default function SmallDatePicker({
  handleDate,
  selectedDate,
  className,
  showMonthOnly,
  style,
  placeholder = "날짜선택"
}: DateSelectProps) {
  const { headers, body, cursorDate, navigation } = useCalendar();

  const handlePrevYear = () => {
    navigation.setDate(
      new Date(cursorDate.getFullYear() - 1, cursorDate.getMonth())
    );
  };

  const handleNextYear = () => {
    navigation.setDate(
      new Date(cursorDate.getFullYear() + 1, cursorDate.getMonth())
    );
  };

  return (
    <Menu
      as="div"
      className={cx("datePickerComp", styles.dateMenu, className)}
      style={style}
    >
      <Menu.Button as="div">
        {({ open }) => {
          return (
            <div className={cx(styles.smallDateButton, { open })}>
              <CalendarIcon stroke="#999" width={22} height={22} />
              <InputValue>
                {selectedDate
                  ? showMonthOnly
                    ? format(selectedDate, "yyyy.MM")
                    : format(selectedDate, "yyyy.MM.dd")
                  : placeholder}
              </InputValue>
            </div>
          );
        }}
      </Menu.Button>
      <Menu.Items className={styles.menuItems}>
        {/* <Menu.Item>
          {({ close }) => {
            return <CloseButton onClick={close} />;
          }}
        </Menu.Item> */}

        {/* <hr /> */}
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <YearAndMonth>{format(cursorDate, "yyyy년")}</YearAndMonth>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
          <TopItem>
            <PrevMonthIcon onClick={navigation.toPrev} width={24} height={24} />
            <YearAndMonth>{format(cursorDate, "M월")}</YearAndMonth>
            <NextMonthIcon onClick={navigation.toNext} width={24} height={24} />
          </TopItem>
        </TopWrapper>

        <StyleWeekRow>
          {headers.weekDays.map(({ key, value }) => {
            return (
              <DayText key={key}>{format(value, "E", { locale: ko })}</DayText>
            );
          })}
        </StyleWeekRow>

        {body.value.map((week) => {
          const { key, value: days } = week;

          return (
            <StyleWeekRow key={key}>
              {days.map((day) => {
                const {
                  key,
                  date,
                  isCurrentDate,
                  isCurrentMonth,
                  isWeekend,
                  value
                } = day;

                const isSelected = selectedDate === value.getTime();
                return (
                  <DayText
                    key={key}
                    as="span"
                    className={cx(styles.day, {
                      isCurrentMonth,
                      isSelected,
                      isCurrentDate,
                      isWeekend
                    })}
                    onClick={() => handleDate(value)}
                  >
                    {date}
                  </DayText>
                );
              })}
            </StyleWeekRow>
          );
        })}
      </Menu.Items>
    </Menu>
  );
}

const StyleWeekRow = styled.div`
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

const DayText = styled.div`
  padding: 8px 9.4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black222};
  &:nth-child(1) {
    color: #ff6969;
  }
  &:nth-child(7) {
    color: #0085ff;
  }
  &:hover {
    background-color: #f0eeff;
  }
`;
const Typography = styled.span``;

const InputValue = styled.span`
  flex: 1;
  color: #999;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
`;

const CloseButton: any = styled(CloseIcon)`
  align-self: flex-end;
  margin: 8px;
  cursor: pointer;
`;

const TopWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
`;

const TopItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;

const PrevYearIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextYearIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const PrevMonthIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextMonthIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;

const YearAndMonth = styled(S.StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black222};
`;
