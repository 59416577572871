import { ListItem } from "core/components/common/Select";
import { COMPANY_CODE } from "../constants/enums";

const contrants = {
  defaultColor: "#6c62d1",
  projectTitle: "SW ERP",
  popDetailSizeRule1: 1024, // 멀티뷰 최소너비 기준지점
  popDetailSizeRule2: "800px", //멀티뷰최소너비
  popDetailSizeRule3: "80%", //  멀티뷰 최소너비 이상일때 너비 비율
  popDetailSizeRule4: "700px", // 싱글뷰 기준 너비
  rightFixedWidth: "550px",
  highrightOrderCount: 100000 //주문조회리스트 하이라이트
};

export const PAGE_PER_COUNT: ListItem[] = [
  { name: "20", value: "20" },
  { name: "50", value: "50" }
];

export const EmployEssentialColumn: {
  label: string;
  key: string;
  type: "string" | "nubmer" | "array";
  position: "기본정보" | "비공개정보" | "기타정보";
}[] = [
  // {
  //   label: "업무구분",
  //   key: "staff_work_type",
  //   position: "기타정보",
  //   type: "string"
  // }
  // {
  //   label: "알람사용",
  //   key: "alarm_yn",
  //   position: "기타정보",
  //   type: "string"
  // }
];

export const ScheduleColorList = ["#FFF1CE", "#E3EFDA", "#FDDAEC", "#DAE3F2"];

export const CompanyList = [
  { label: "성원애드피아", value: "1", code: COMPANY_CODE.Adpia },
  { label: "애드피아몰", value: "2", code: COMPANY_CODE.AdpiaMall },
  { label: "푸드몰", value: "3", code: COMPANY_CODE.FoodMall }
];

export default contrants;

export const Whitelist = ["swpia", "swkang"];

export const HROrgNo = "124"

export const HR_PERMISSION = "HR_ADMIN"

export const DEV_PERMISSION = "DEV_ADMIN"

export const SUPER_PERMISSION = "SUPER_ADMIN"
  
export const NORMAL_PERMISSION = "NORMAL"